import { AxiosResponse } from 'axios'
import { getKeyLang } from '../helpers'
import {
  IBookingRequest,
  IBookingResponse,
  IDate,
  IDateCheckout,
  IDatesCheckoutParamsQuery,
  IDatesParamsQuery,
  Identificator,
  IDestination,
  IHotel,
  IHotelDetailParamsQuery,
  IHotelsParamsQuery,
  IInitDataParametersQuery,
  IInitDataResponse,
  ILanguage,
  ILoyaltyProgram,
  IPrice,
  IVisitCategory,
  IVisitTypeCategoryParamsQuery,
} from '../store/data'
import axios from './axios'

const GET_DATES_DEBOUNCE_INTERVAL = 10

let abortController: AbortController | null = null
let debounceTimer: NodeJS.Timeout | null = null

export const getDatesApiRequest = (params: IDatesParamsQuery): Promise<AxiosResponse<IDate[]>> => {
  if (debounceTimer) {
    clearTimeout(debounceTimer)
  }

  if (abortController) {
    abortController.abort()
  }

  return new Promise((resolve, reject) => {
    debounceTimer = setTimeout(() => {
      abortController = new AbortController()

      axios
        .get<IDate[]>('/calendar/dates', {
          params,
          signal: abortController.signal,
        })
        .then((response) => {
          abortController = null
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    }, GET_DATES_DEBOUNCE_INTERVAL)
  })
}

export const getDatesCheckoutApiRequest = (
  params: IDatesCheckoutParamsQuery
): Promise<AxiosResponse<IDateCheckout[]>> =>
  axios.get<IDateCheckout[]>('/calendar/checkouts', { params })
export const getHotelApiRequest = (
  hotelId: Identificator,
  params?: IHotelDetailParamsQuery
): Promise<AxiosResponse<IHotel>> => {
  const config: { params?: IHotelDetailParamsQuery } = {}

  if (params && Object.keys(params).length > 0) {
    config.params = params
  }

  return axios.get<IHotel>(`/hotels/${hotelId}`, config)
}
export const getHotelsApiRequest = (params: IHotelsParamsQuery): Promise<AxiosResponse<IHotel[]>> =>
  axios.get<IHotel[]>('/hotels', {
    params: { ...params, include_room_types: params?.include_room_types ?? true },
  })
export const getLowestPrice = (params: URLSearchParams): Promise<AxiosResponse<IPrice>> =>
  axios.get<IPrice>('/lowest-price', { params })
export const getLoyaltyProgram = (): Promise<AxiosResponse<ILoyaltyProgram[]>> =>
  axios.get<ILoyaltyProgram[]>('/loyalty-programs')
export const getPriceApiRequest = (params: URLSearchParams): Promise<AxiosResponse<IPrice>> =>
  axios.get<IPrice>('/price', { params })
export const getVisitCategoriesApiRequest = (
  params: IVisitTypeCategoryParamsQuery
): Promise<AxiosResponse<IVisitCategory[]>> =>
  axios.get<IVisitCategory[]>('/visit-type-categories', {
    params: { ...params, include_visit_types: true },
  })
export const getDestinations = (): Promise<AxiosResponse<IDestination[]>> =>
  axios.get<IDestination[]>('/destinations')
export const sendBookingApiRequest = (
  data: IBookingRequest,
  language: ILanguage
): Promise<AxiosResponse<IBookingResponse>> =>
  axios.post('/booking', data, { headers: { 'Accept-Language': getKeyLang(language) } })
export const getBookingConfiguration = (
  params: IInitDataParametersQuery
): Promise<AxiosResponse<IInitDataResponse>> => axios.get<IInitDataResponse>('/booking', { params })
